.ToolbarContainer {
  background: var(--chakra-colors-textHighlight);
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid var(--chakra-colors-borderLight);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  margin: -20px -20px 20px -20px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.divider {
  border: none;
  padding: 12px 0;
  border-left: 1px solid var(--chakra-colors-borderLight);
}

.ToolbarContainer svg {
  height: 16px;
}
