/* Dropdown menu */
:root {
  --shadow-color: 0deg 0% 51%;
  --shadow-elevation-medium: 0px 0.1px 0.2px hsl(var(--shadow-color) / 0),
    -0.1px 0.5px 0.8px hsl(var(--shadow-color) / 0.15), -0.2px 0.8px 1.2px hsl(var(--shadow-color) / 0.31),
    -0.3px 1.4px 2.1px hsl(var(--shadow-color) / 0.46), -0.5px 2.5px 3.8px hsl(var(--shadow-color) / 0.61);
}

.dropdown-menu {
  background: white;
  color: var(--chakra-colors-menuBackground);
  border-radius: 0.7rem;
  box-shadow: var(--shadow-elevation-medium);
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  overflow: auto;
  padding: 0.4rem;
  position: relative;

  button {
    align-items: center;
    background-color: transparent;
    display: flex;
    gap: 0.25rem;
    text-align: left;
    width: 100%;
    padding: 0.125rem 0.5rem;

    &:hover,
    &:hover.is-selected {
      background-color: var(--chakra-colors-menuBackground);
      color: white;
    }

    &.is-selected {
      background-color: var(--chakra-colors-menuBackground);
      color: white;
    }
  }
}
