.grabbable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  z-index: 10;
}

.delete {
  padding: 0.5rem;
}

.buttonsDiv > button:focus {
  outline: none;
  box-shadow: 0 0 0 1px var(--chakra-colors-borderFocus);
  border-radius: 4px;
}
