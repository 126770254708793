audio {
  width: 100%;
}

audio::-webkit-media-controls-panel {
  height: 100%;
  width: 99%;
  border-radius: 0;
  /* background-color: #e6e6e6; */
}

wave {
  z-index: 0;
}
