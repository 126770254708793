#flowAssigned .chakra-avatar {
  border: 1px solid white !important;
}

#flowAssigned .chakra-avatar__initials {
  color: white !important;
}

#flowAssigned .chakra-avatar__excess {
  color: white !important;
  border: 1px solid white !important;
  background-color: 333333 !important;
}
