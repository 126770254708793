/* Basic editor styles */
.tiptapCommentCard {
  color: var(--chakra-colors-textSoft);
  font-weight: 400;
  font-size: 14px;
  flex: 1;
  min-height: 23px;

  ::selection {
    background-color: var(--chakra-colors-menuBackground);
    color: var(--chakra-colors-textHighlight);
  }

  :first-child {
    margin-top: 0;
  }

  .mention {
    color: var(--chakra-colors-accentIcon);
    font-weight: 600;
    border-radius: 0.4rem;
    box-decoration-break: clone;
    padding: 0 0.125rem;
  }
}
