/* OVERALL STYLES */

.tiptap {
  font-family: 'Inter';
  color: var(--chakra-colors-textBlack);
  padding: 0.5rem 1rem 0;
}

.tiptap *::selection {
  background-color: var(--chakra-colors-cardBackground);
  color: white;
}

.tiptap p {
  margin: 1rem 0;
}

.tiptap a {
  text-decoration: underline;
  color: var(--chakra-colors-accent);
}

.tiptap hr {
  border: none;
  border-top: 1px solid var(--chakra-colors-background);
  margin: 20px 0;
}

.tiptap br {
  margin-bottom: 10px;
}

/* LISTS & BLOCKQUOTE */

.tiptap ul {
  margin: 0;
  padding-left: 1rem;
  list-style-type: disc;
}

.tiptap ol {
  margin: 0;
  padding-left: 1rem;
  list-style-type: decimal;
}

.tiptap li > p {
  margin: 0;
}

.tiptap blockquote {
  border-left: 2px solid var(--chakra-colors-background);
  padding-left: 10px;
  margin: 0;
}

/* TASKLIST */

ul[data-type='taskList'] {
  list-style: none;
  padding: 0;
}

ul[data-type='taskList'] p {
  margin: 0;
}

ul[data-type='taskList'] li {
  display: flex;
  align-items: center;
}

ul[data-type='taskList'] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

ul[data-type='taskList'] li > div {
  flex: 1 1 auto;
}

ul[data-type='taskList'] ul li,
ul[data-type='taskList'] ol li {
  display: list-item;
}

ul[data-type='taskList'] > li {
  display: flex;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--chakra-colors-textHighlight);
  margin: 0;
}

input[type='checkbox'] {
  appearance: none;
  background-color: var(--chakra-colors-textHighlight);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 2px solid var(--chakra-colors-cardBackground);
  border-radius: 4px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--chakra-colors-accent);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='checkbox']:focus {
  outline: max(1px, 0.15em) solid currentColor;
  outline-offset: max(1px, 0.15em);
}

/* HEADINGS */

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  font-weight: 700;
}

.tiptap h1 {
  font-size: 2.25rem;
}
.tiptap h2 {
  font-size: 1.75rem;
}
.tiptap h3 {
  font-size: 1.4rem;
}
.tiptap h4 {
  font-size: 1.125rem;
}
.tiptap h5 {
  font-size: 1rem;
}
.tiptap h6 {
  font-size: 0.85rem;
}

.tiptap strong {
  font-weight: bold;
}

.tiptap em {
  font-style: italic;
}

.tiptap del {
  text-decoration: line-through;
}

/* CODE */

.tiptap pre {
  color: var(--chakra-colors-textSoft);
  background: var(--chakra-colors-cardBackground);
  font-family: 'ui-monospace', 'SFMono-Regular', 'SF Mono', Menlo, Consolas, 'Liberation Mono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
}
.tiptap pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
  border: none;
}

.tiptap code {
  font-family: monospace;
  color: var(--chakra-colors-textSoft);
  background: var(--chakra-colors-cardBackground);
  padding: 2px 4px;
  border-radius: 3px;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}

/* CLASSNAMES */

.WhiteCard {
  color: var(--chakra-colors-background);
  background: #ffffff;
  border: 2px solid var(--chakra-colors-cardBackground);
  border-radius: 10px;
  margin: 0 auto;
  padding: 20px;
}
